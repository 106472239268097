import { useEffect, useState } from 'react';
import { Modal, ModalButton } from '../shared/Modal';
import i18n from '../../i18n';

interface LanguageChangeModalProps {
  isShowModal: boolean;
  text: string;
  title: string;
  isInformation: boolean;
  searchLanguage: string;
  onReject: () => void;
  onConfirm: () => void;
  onClose: (isClosed: boolean) => void;
}

export const LanguageChangeModal = (props: LanguageChangeModalProps) => {
  const {
    isShowModal,
    text,
    isInformation,
    searchLanguage,
    title,
    onReject,
    onConfirm,
    onClose,
  } = props;

  const i18n2 = i18n.cloneInstance();
  i18n2.changeLanguage(searchLanguage);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(isShowModal);
  }, [isShowModal]);

  const renderButtons = (): ModalButton[] => {
    const buttons: ModalButton[] = [];

    if (!isInformation) {
      buttons.push({
        label: i18n2.t('language.modal.reject'),
        onClick: () => {
          setShowModal(false);
          onReject();
        },
      });

      buttons.push({
        label: i18n2.t('language.modal.confirm'),
        onClick: () => {
          onConfirm();
          setShowModal(false);
        },
      });
    } else {
      buttons.push({
        label: 'Ok',
        onClick: () => {
          onConfirm();
          setShowModal(false);
        },
      });
    }

    return buttons;
  };

  return (
    <Modal
      isShowModal={showModal}
      onClose={() => onClose(false)}
      body={<p>{text}</p>}
      title={title}
      buttons={renderButtons()}
    />
  );
};
