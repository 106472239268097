import { SearchPanel } from './SearchPanel.tsx';
// import { Breadcrumb } from './Breadcrumb.tsx';
import { Header } from '../header/Header.tsx';

export const Search = () => {
  return (
    <>
      <Header />
      <SearchPanel />
    </>
  );
};
