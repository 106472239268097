import robot from '/robot.svg';
import Linkify from 'react-linkify';

interface SemanticSearchResultProps {
  text: string
}
export const SemanticSearchResult = (props: SemanticSearchResultProps) => {
  return (
    <div className="max-w-content mx-auto relative">
      {props.text && props.text.length > 0 ? (
        
        <div className="w-full bg-searchResult rounded-[11px] my-5 flex pl-[34px] py-3.5 pr-[23px] justify-between  pr-[34px]">
          <div className="flex-none m-auto pr-[34px] mx-[12px]">
            <img src={robot} alt="File" />
          </div>
          <div className="mt-4 mr-3">
            <Linkify componentDecorator={componentDecorator}>
              {props.text.replace(/ \[[\s\S]*?\]/g, '').replace(/\[[\s\S]*?\]/g, '')}
            </Linkify>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const componentDecorator = (href:any, text:any, key:any) => (
  <a href={href} key={key} target="_blank" className='text-current underline'>
    {text}
  </a>
);
