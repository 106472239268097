import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import frTranslations from '../src/translations/fr.translations.json';
import enTranslations from '../src/translations/en.translations.json';
import deTranslations from '../src/translations/de.translations.json';

const resources = {
  fr: {
    translation: frTranslations,
  },
  en: {
    translation: enTranslations,
  },
  de: {
    translation: deTranslations,
  },
};

i18n.use(initReactI18next).init({
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;
