import { useTranslation } from 'react-i18next';
import { FollowUpQuestionItem } from './FollowUpQuestionItem';

interface FollowUpQuestionsListProps {
  followUpQuestions: string[];
  setSelectedValue: (value: string) => void;
}
export const FollowUpQuestionsList = (props: FollowUpQuestionsListProps) => {
  const { t } = useTranslation();

  return (
    <>
      {props.followUpQuestions.length > 0 ? (
        <div className="max-w-content mx-auto mb-[17px] w-full flex left">
          <p className="text-[16px] text-[#FFF] text-left tracking-[3.2px]">
            {t('search.followupquestions.label')}
          </p>
        </div>
      ) : null}
      <div className="max-w-content mx-auto flex flex-row flex-wrap">
        {props.followUpQuestions.map((e, index) => {
          return (
            <FollowUpQuestionItem
              key={index}
              value={e}
              onSelectValue={(e) => props.setSelectedValue(e)}
            />
          );
        })}
      </div>
    </>
  );
};
