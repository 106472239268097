import { useEffect, useState } from 'react';
import { Language } from '../../models/langEnum';
import { FileUploadComponent } from '../shared/FileUpload';
import { SettingsRequest } from '../../models/SettingsRequest';
import { useTranslation } from 'react-i18next';
import { Modal } from '../shared/Modal';

interface SearchSettingsModalProps {
  initValues: SettingsRequest;
  lang: Language;
  isShowModal: boolean;
  onClose: (isClosed: boolean) => void;
  onValidate: (request: SettingsRequest) => void;
}

export const SearchSettingsModal = (props: SearchSettingsModalProps) => {
  const { initValues, isShowModal, lang, onClose, onValidate } = props;

  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const [resultPerPage, setResultPerPage] = useState<number>(5);
  const [maxResult, setMaxResult] = useState<number>(25);
  const [minScore, setMinScore] = useState<number>(0.5);
  const [minReranker, setMinReranker] = useState<number>(0.5);
  const [enableSemanticResult, setEnableSemanticResult] =
    useState<boolean>(false);
  const [enableFollowUpQuestion, setEnableFollowUpQuestion] =
    useState<boolean>(true);

  useEffect(() => {
    setMaxResult(initValues.MaxResult);
    setResultPerPage(initValues.ResultPerPage);
    setMinScore(initValues.MinScore ?? 0);
    setMinReranker(initValues.MinReranker ?? 0);
    setEnableSemanticResult(initValues.EnableSemanticResult);
    setEnableFollowUpQuestion(initValues.EnableFollowUpQuestion);
  }, [initValues]);

  useEffect(() => {
    setShowModal(isShowModal);
  }, [isShowModal]);

  return (
    <Modal
      isShowModal={showModal}
      onClose={() => onClose(false)}
      body={
        <form className="w-full">
          <div className="flex flex-row items-center justify-between mb-[40px]">
            <label className=" text-[#637255] text-[22px] font-bold">
              {t('parameters.displayResults')}
            </label>
            <FileUploadComponent
              language={lang}
              onAfterUpload={() => onClose(false)}
            />
          </div>
          <div className="flex flex-row items-center justify-between mb-4">
            <label className="basis-1/4">
              {t('parameters.enableSemanticResult')}
            </label>
            <input
              type="checkbox"
              onChange={(e) => setEnableSemanticResult(e.target.checked)}
              checked={enableSemanticResult}
            />
          </div>
          <div className="flex flex-row items-center justify-between mb-4">
            <label className="basis-1/4">
              {t('parameters.enableFollowUpQuestion')}
            </label>
            <input
              type="checkbox"
              onChange={(e) => setEnableFollowUpQuestion(e.target.checked)}
              checked={enableFollowUpQuestion}
            />
          </div>
          <div className="flex flex-row items-center justify-between mb-4">
            <label className="basis-1/4">
              {t('parameters.resultsPerPage')}
            </label>
            <input
              className="basis-3/4 h-10 border-2 border-[#637255] rounded-[5px] p-3"
              type="number"
              onChange={(e) => setResultPerPage(parseInt(e.target.value))}
              value={resultPerPage}
            />
          </div>
          <div className="flex flex-row items-center justify-between mb-4">
            <label className="basis-1/4">{t('parameters.scoreMinimum')}</label>
            <input
              className="basis-3/4 h-10 border-2 border-[#637255] rounded-[5px] p-3"
              type="number"
              step="1"
              onChange={(e) => setMinScore(parseFloat(e.target.value))}
              value={minScore}
            />
          </div>
          <div className="flex flex-row items-center justify-between mb-4">
            <label className="basis-1/4">{t('parameters.rerankerMinimum')}</label>
            <input
              className="basis-3/4 h-10 border-2 border-[#637255] rounded-[5px] p-3"
              type="number"
              step="1"
              onChange={(e) => setMinReranker(parseFloat(e.target.value))}
              value={minReranker}
            />
          </div>
        </form>
      }
      title={t('parameters.title')}
      buttons={[
        {
          label: t('parameters.submit'),
          onClick: () => {
            setShowModal(false);
            onValidate({
              MinScore: minScore,
              MinReranker: minReranker,
              ResultPerPage: resultPerPage,
              MaxResult: maxResult,
              EnableSemanticResult: enableSemanticResult,
              EnableFollowUpQuestion: enableFollowUpQuestion,
              Sorting: initValues.Sorting,
            });
          },
        },
      ]}
    />
  );
};
