import { IPublicClientApplication } from '@azure/msal-browser';
import { config } from '../../configuration/config.ts';
import { getRedirectUri } from '../authConfig.ts';

const getHeaders = async (
  idToken: string | undefined,
): Promise<Record<string, string>> => {
  var headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };
  // If using login, add the id token of the logged in account as the authorization

  if (config.useLogin) {
    if (idToken) {
      headers['Authorization'] = `Bearer ${idToken}`;
    }
  }

  return headers;
};

export const fetchData = async <T>(
  route: string,
  idToken: string | undefined,
  client: IPublicClientApplication,
): Promise<T> => {
  try {
    const response = await fetch(config.apiUrl + route, {
      headers: await getHeaders(idToken),
    });

    if (!response.ok) {
      if (response.status === 401) {
        await client.acquireTokenRedirect({
          scopes: ['user.read', 'openid', 'profile', 'offline_access'],
          redirectUri: getRedirectUri('/'),
        });
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const postData = async <T>(
  route: string,
  body: object,
  idToken: string | undefined,
  client: IPublicClientApplication,
  signal?: AbortSignal,
): Promise<T> => {
  try {
    const response = await fetch(config.apiUrl + route, {
      method: 'POST',
      headers: await getHeaders(idToken),
      body: JSON.stringify(body),
      signal: signal,
    });

    if (!response.ok) {
      if (response.status === 401) {
        await client.acquireTokenRedirect({
          scopes: ['user.read', 'openid', 'profile', 'offline_access'],
          redirectUri: getRedirectUri('/'),
        });
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
