import { Outlet, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import i18next from "i18next";

const Layout = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('lang')) {
      setSearchParams({ lang: 'fr' });
    } else {
      i18next.changeLanguage(searchParams.get('lang')!);
    }
  }, [searchParams, setSearchParams]);

  return (
    <div className="h-full w-full pb-12">
      <Outlet />
    </div>
  );
};

export default Layout;
