import { useEffect, useState } from 'react';

export type ModalButton = {
  label: string;
  onClick: () => void;
};

interface ModalProps {
  title: string;
  body: React.ReactNode;
  isShowModal: boolean;
  onClose: (isClosed: boolean) => void;
  buttons: ModalButton[];
}

export const Modal = (props: ModalProps) => {
  const { title, body, isShowModal, onClose, buttons } = props;

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(isShowModal);
  }, [isShowModal]);

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed flex justify-center items-center bg-[#555555b3] overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="w-1/2 my-6 mx-auto max-w-3xl z-500">
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex flex-row items-center justify-between px-10 py-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-[#555] text-[24px] font-bold">{title}</h3>
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => onClose(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        d="M2 2L17.5 17.5"
                        stroke="#608100"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.5 2L2 17.5"
                        stroke="#608100"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="relative px-10 py-5 flex-auto">{body}</div>
                <div className="flex items-center justify-end px-10 py-5 ">
                  {buttons.map((e) => {
                    return (
                      <>
                        <button
                          key={e.label}
                          className="text-white bg-[#637255] px-6 py-3 rounded-[10px] outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                          onClick={() => {
                            setShowModal(false);
                            e.onClick();
                          }}
                        >
                          {e.label}
                        </button>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
