import { IPublicClientApplication } from '@azure/msal-browser';
import {
  GetDocumentRequest,
  SearchRequest,
  SearchResult,
  SuggestionRequest,
  SuggestionResult,
  FollowUpQuestionsResponse,
  FollowupQuestionsRequest,
  LanguageResult,
  LanguageRequest,
  SemanticAnswerRequest,
  SemanticAnswerResponse,
} from '../models/Search.ts';
import { postData } from './apiService.ts';

export const getSearchResults = async (
  searchRequest: SearchRequest,
  idToken: string | undefined,
  client: IPublicClientApplication,
) => {
  try {
    return await postData<SearchResult>('ask', searchRequest, idToken, client);
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getLanguage = async (
  languageRequest: LanguageRequest,
  idToken: string | undefined,
  client: IPublicClientApplication,
) => {
  try {
    return await postData<LanguageResult>(
      'language',
      languageRequest,
      idToken,
      client,
    );
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getSuggestions = async (
  request: SuggestionRequest,
  signal: AbortSignal,
  idToken: string | undefined,
  client: IPublicClientApplication,
) => {
  try {
    return await postData<SuggestionResult>(
      'suggest',
      request,
      idToken,
      client,
      signal,
    );
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getDocument = async (
  request: GetDocumentRequest,
  idToken: string | undefined,
  client: IPublicClientApplication,
) => {
  try {
    return await postData<SearchResult>(
      'getdocument',
      request,
      idToken,
      client,
    );
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getFollowUpQuestionsQuery = async (
  request: FollowupQuestionsRequest,
  idToken: string | undefined,
  signal: AbortSignal,
  client: IPublicClientApplication,
) => {
  try {
    return await postData<FollowUpQuestionsResponse>(
      'followupquestions',
      request,
      idToken,
      client,
      signal,
    );
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getSemanticAnswerQuery = async (
  request: SemanticAnswerRequest,
  idToken: string | undefined,
  client: IPublicClientApplication,
  signal: AbortSignal,
) => {
  try {
    return await postData<SemanticAnswerResponse>(
      'ask_semantic',
      request,
      idToken,
      client,
      signal,
    );
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
