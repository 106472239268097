export const SearchResultItemLoading = () => {
  return (
    <div className="[&>*]:animate-pulse w-full bg-searchResult rounded-[11px] my-5 flex pl-[34px] py-3.5 pr-[23px] justify-between">
      <div className="m-auto pr-[34px]">
        <div className="bg-zinc-300 rounded-full h-7 w-7"></div>
      </div>
      <div className="flex flex-col w-full pr-8">
        <div className="bg-zinc-300 h-4 w-20 mb-2"></div>
        <div className="bg-zinc-300 h-4 w-full mb-2"></div>
        <div className="bg-transparent h-4 w-4/5 mb-2 "></div>
        <div className="bg-zinc-300 h-4 w-1/2"></div>
      </div>
      <div className="my-auto">
        <div className="w-[57px]">
          <div className="bg-zinc-300 h-7 w-[57px] rounded-sm"></div>
        </div>
      </div>
    </div>
  );
};
