import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Language } from '../../models/langEnum.ts';
import parametersIcon from '/parametersIcon.svg';
import searchIcon from '/searchIcon.svg';
import {
  SuggestionResult,
  SuggestionResultValue,
} from '../../models/Search.ts';
import { IconButton } from '../shared/IconButton.tsx';
// import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { config } from '../../../configuration/config.ts';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
  onHitEnter: () => void;
  onClickParams: () => void;
  handleSelectAutocomplete: (value: string) => void;
  fetchAutocompleteValues: (
    search: string,
    onSuggestionsFetched: (result: SuggestionResult) => void,
  ) => void;
  isLoadingSuggestions: boolean;
}
export const SearchInput = ({
  value,
  onChange,
  onClick,
  onHitEnter,
  onClickParams,
  //  fetchAutocompleteValues,
  handleSelectAutocomplete,
  isLoadingSuggestions,
}: SearchInputProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [lang, setLang] = useState<Language | undefined>(undefined);
  const [autocompleteValues, setAutocompleteValues] = useState<
    SuggestionResultValue[]
  >([]);
  const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isCancelRequested, setIsCancelRequest] = useState<boolean>(false);
  const { accounts } = useMsal();

  useEffect(() => {
    if (searchParams && searchParams.get('lang') !== null && !lang) {
      setLang(searchParams.get('lang') as Language);
    }
  }, [searchParams]);

  // const fetchAutocomplete = useCallback(
  //   _.debounce((search) => {
  //     if (search.trim() !== '') {
  //       fetchAutocompleteValues(search, (result) => {
  //         setAutocompleteValues(result?.suggestions.map((value) => value));
  //         setShowAutocomplete(
  //           result.suggestions.length > 0 &&
  //             result.suggestions[0].text !== search,
  //         );
  //       });
  //     } else {
  //       setAutocompleteValues([]);
  //     }
  //   }, 400),
  //   [],
  // );

  useEffect(() => {
    if (inputValue.trim() !== '') {
      setIsCancelRequest(false);
      // fetchAutocomplete(inputValue);
    } else if (
      autocompleteValues.length === 1 &&
      autocompleteValues[0].text.trim().toLowerCase() ===
        inputValue.trim().toLowerCase()
    ) {
      setAutocompleteValues([]);
    } else {
      setAutocompleteValues([]);
      setShowAutocomplete(false);
    }
  }, [inputValue]);

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onHitEnter();
      setAutocompleteValues([]);
      setShowAutocomplete(false);
      setIsCancelRequest(true);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    onChange(event.target.value);
  };

  const handleSelect = (index: string, value: string) => {
    setInputValue(value);
    setShowAutocomplete(false);
    onChange(value);
    handleSelectAutocomplete(index);
  };

  const onClickSearch = () => {
    onClick();
    setAutocompleteValues([]);
    setShowAutocomplete(false);
    setIsCancelRequest(true);
  };

  return (
    <div className="relative">
      <div className="absolute flex right-[0.7rem] top-[0.7rem] bg-[#ffffff] z-30">
        <IconButton
          iconPath={searchIcon}
          text={t('search')}
          onClick={onClickSearch}
        />
        {accounts[0]?.username != config.userCtie && (
          <img
            src={parametersIcon}
            className="h-[41px] w-[41px] cursor-pointer overflow-hidden"
            alt="File"
            onClick={onClickParams}
          />
        )}
      </div>
      <div className="w-full absolute bg-[#ffffff] border-[3px] border-[#608100] rounded-[32px] px-2 overflow-hidden z-20">
        <input
          value={value}
          onChange={handleInputChange}
          onKeyDown={(e) => onPressEnter(e)}
          className="w-full h-[59px] pl-4 pr-[18%] py-2 placeholder:italic placeholder:font-semibold placeholder-greenGuichet text-base placeholder:text-base border-b-2 pb-[5px] outline-none"
          placeholder={t('search.placeholder')}
        />

        <ul
          className={`max-h-[250px] overflow-x-hidden overflow-y-auto ${
            showAutocomplete && !isCancelRequested ? 'pb-[10px]' : ''
          }`}
        >
          {/* {isLoadingSuggestions ? ( */}
          {isLoadingSuggestions ? (
            <li
              key={'isLoadKey'}
              className="py-[10px] px-[80px] hover:bg-[#6372554D] mx-[-20px]  text-base"
            >
              {t('search.loading')}...
            </li>
          ) : null}
          {!isLoadingSuggestions &&
            !isCancelRequested &&
            showAutocomplete &&
            autocompleteValues.map((item, index) => {
              return (
                <li
                  key={index}
                  className="py-[10px] px-[80px] hover:bg-[#6372554D] mx-[-20px] cursor-pointer text-base"
                  onClick={() => handleSelect(item.id, item.text)}
                >
                  {item.text}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
