import { useEffect, useState } from 'react';
import {
  FilterDropdownValues,
  FilterItemKeyValue,
  SearchFilters,
  SearchResult,
  SearchResultValue,
} from '../../models/Search.ts';
import { SearchResultItem } from './SearchResultItem.tsx';
import { SearchResultItemLoading } from './SearchResultItemLoading.tsx';
import { SearchResultsFilter } from './SearchResultsFilter.tsx';
import { useTranslation } from 'react-i18next';
import { getUniqueValues } from '../../helpers/array.helper.ts';
import noResultIcon from '/noResultIcon.svg';
import SortItem from './SortItem.tsx';
import { useLocalStorage } from '@uidotdev/usehooks';
import { SettingsRequest } from '../../models/SettingsRequest.ts';

interface SearchResultsListProps {
  initResults?: SearchResult;
  results?: SearchResult;
  isLoading: boolean;
  maxPerPage: number;
  searchId: string;
  filterResult: (filter: SearchFilters) => void;
  sortResult: (value: string) => void;
}
export const SearchResultsList = ({
  initResults,
  results,
  isLoading,
  maxPerPage,
  filterResult,
  searchId,
  sortResult,
}: SearchResultsListProps) => {
  const { t } = useTranslation();
  const [iteration, setIteration] = useState<number>(1);
  const [displayItems, setDisplayItems] = useState<SearchResultValue[]>([]);
  const [isLoadingInfiniteScroll, setIsLoadingInfiniteScroll] =
    useState<boolean>(false);
  const [filterDropdownValues, setFilterDropdownValues] =
    useState<FilterDropdownValues>({
      breadcrumb: [],
      targetAudience: [],
      type: [],
    });

  const [localStorage] = useLocalStorage<SettingsRequest>('searchSettings');

  useEffect(() => {
    if (!results || results.values.length === 0) {
      setDisplayItems([]);
      setIsLoadingInfiniteScroll(false);
      setIteration(1);
    }
  }, [results]);

  const getFilterDropdownValues = (array: string[]): FilterItemKeyValue[] => {
    const uniqueValues = getUniqueValues<string>(array);
    const uniqueValuesSorted = uniqueValues.sort();

    return uniqueValuesSorted.map((e) => ({ key: e, value: e }));
  };

  useEffect(() => {
    if (initResults) {
      const breadcrumbValues: string[] = [];
      const typeValues: string[] = [];
      const targetValues: string[] = [];

      initResults.values.map((e) => {
        e.breadcrumb.forEach((p) => {
          if (p.trim() !== '') breadcrumbValues.push(p);
        });
        e.types.forEach((p) => {
          if (p.trim() !== '') typeValues.push(p);
        });
        if (e.targetAudience && e.targetAudience.trim() !== '')
          targetValues.push(e.targetAudience);
      });

      const targetAudiencePostProcess = getFilterDropdownValues(
        targetValues,
      ).map((e) => {
        return {
          key: e.key,
          value: e.value.split('/')[e.value.split('/').length - 1],
        };
      });

      setFilterDropdownValues({
        breadcrumb: getFilterDropdownValues(breadcrumbValues),
        targetAudience: targetAudiencePostProcess,
        type: getFilterDropdownValues(typeValues),
      });
    }
  }, [initResults]);

  useEffect(() => {
    if (results) {
      if (iteration == 1) {
        setDisplayItems([...results.values.slice(0, iteration * maxPerPage)]);
      } else if (
        iteration * maxPerPage < results.values.length ||
        (iteration * maxPerPage) % results.values.length < results.values.length
      ) {
        setDisplayItems([...results.values.slice(0, iteration * maxPerPage)]);
        setIsLoadingInfiniteScroll(false);
      }
    }
  }, [results, iteration]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        !isLoadingInfiniteScroll &&
        results &&
        displayItems.length < results.values.length
      ) {
        setIteration((prev) => prev + 1);
        setIsLoadingInfiniteScroll(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [results, displayItems]);

  const applySearchFilter = (searchFilter: SearchFilters) => {
    filterResult(searchFilter);
  };

  return (
    <div className="max-w-content mx-auto min-h-[312px] relative">
      {results && filterDropdownValues ? (
        <SearchResultsFilter
          breadcrumbFacets={filterDropdownValues.breadcrumb}
          targetAudienceFacets={filterDropdownValues.targetAudience}
          typeFacets={filterDropdownValues.type}
          filterResult={applySearchFilter}
        />
      ) : null}
      <div className="flex justify-between align-baseline">
        <div
          className={`font-bold text-[24px] ${
            results?.values.length ? '' : 'invisible'
          }`}
        >
          <h1>{t('search.title')}</h1>
        </div>
        <div
          className={`font-bold text-[24px] ${
            results?.values.length === 0 ? '' : 'invisible'
          }`}
        >
          {results?.values.length === 0 ? (
            <>
              <div className="mt-16 flex justify-center">
                <img src={noResultIcon} alt="File" />
              </div>
              <div className="mt-8">
                <p className="text-greenGuichet font-normal text-base text-center">
                  {t('search.results.any.text')}
                </p>
              </div>
            </>
          ) : null}
        </div>
        <div
          className={`flex flex-col items-end ${
            results?.values.length ? '' : 'invisible'
          }`}
        >
          <div>
            <p className="text-[14px] font-semibold mt-2">
              {results?.values.length && results?.values.length > 1
                ? `${results?.values.length} ${t('search.resultCount.plural')}`
                : `${results?.values.length} ${t(
                    'search.resultCount.singular',
                  )}`}
            </p>
          </div>
          <div className="mt-2">
            {localStorage ? (
              <SortItem
                defaultValue={localStorage.Sorting}
                onChange={(value: string) => {
                  sortResult(value);
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
      {isLoading &&
        [...Array(3)].map(() => (
          <SearchResultItemLoading key={Math.random()} />
        ))}
      {displayItems?.map((result, index) => (
        <SearchResultItem
          key={result.url}
          searchId={searchId}
          rank={index + 1}
          searchResult={result}
        />
      ))}
      {isLoadingInfiniteScroll &&
        [...Array(3)].map(() => (
          <SearchResultItemLoading key={Math.random()} />
        ))}
    </div>
  );
};
