interface IconButtonProps {
  text: string;
  iconPath: string;
  onClick: () => void;
}

export const IconButton = (props: IconButtonProps) => {
  return (
    <button
      onClick={props.onClick}
      className="flex bg-greenGuichet text-white py-2 px-4 mr-2 rounded-3xl"
    >
      <img src={props.iconPath} className="mr-3" />
      {props.text}
    </button>
  );
};
