interface LanguageItem {
  value: string;
  isActif: boolean;
  onClick: (val: string) => void;
}

export const LanguageItem = (props: LanguageItem) => {
  return (
    <p
      className={`mb-0 border-[1px] p-[8px] ${
        props.isActif ? 'border-grey' : 'border-none'
      } rounded-[10px] mr-[10px] text-[14px] cursor-pointer`}
      onClick={() => props.onClick(props.value.toLowerCase())}
    >
      {props.value}
    </p>
  );
};
