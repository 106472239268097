import {useTranslation} from "react-i18next";

export const SubHeader = () => {
    const { t } = useTranslation();
  return (
    <div className="flex items-center h-[60px]">
      <ul className="flex justify-between w-full font-semibold">
        <li>{t('header.procedures')}</li>
        <li>{t('header.news')}</li>
        <li>{t('header.help')}</li>
        <li>{t('header.contact')}</li>
      </ul>
    </div>
  );
};
