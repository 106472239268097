import {
  FilterItemKeyValue,
  FilterType,
  SearchFilters,
} from '../../models/Search';
import { FilterItem } from '../shared/FilterItem';
import fileIcon from '/fileIcon.svg';
import targetIcon from '/targetIcon.svg';
import packageIcon from '/packageIcon.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SearchResultsFilterProps {
  breadcrumbFacets: FilterItemKeyValue[];
  targetAudienceFacets: FilterItemKeyValue[];
  typeFacets: FilterItemKeyValue[];
  filterResult: (searchFilter: SearchFilters) => void;
}

export const SearchResultsFilter = (props: SearchResultsFilterProps) => {
  const { t } = useTranslation();
  const [searchFilter, setSearchFilter] = useState<SearchFilters>({
    breadcrumb: [],
    targetAudience: [],
    type: [],
  });
  const [foldStatusItems, setFoldStatusItems] = useState<
    { type: FilterType; status: boolean }[]
  >([
    {
      type: 'target',
      status: false,
    },
    {
      type: 'type',
      status: false,
    },
    {
      type: 'theme',
      status: false,
    },
  ]);

  useEffect(() => {
    if (searchFilter) props.filterResult(searchFilter);
  }, [searchFilter]);

  const updateSearchFilter = (
    filterType: FilterType,
    element: FilterItemKeyValue,
    isRemoveTrigger?: boolean,
  ) => {
    const filter: SearchFilters = { ...searchFilter };
    if (filterType === 'target') {
      if (
        element.key !== t('search.filter.all') &&
        filter.targetAudience &&
        filter.targetAudience.find(
          (e) =>
            e.trim().toLowerCase() === element.value.trim().toLocaleLowerCase(),
        ) === undefined
      ) {
        filter.targetAudience.push(element.key);
      } else if (element.key === t('search.filter.all')) {
        filter.targetAudience = [];
      } else if (
        element.key !== t('search.filter.all') &&
        filter.targetAudience &&
        filter.targetAudience.find(
          (e) =>
            e.trim().toLowerCase() === element.value.trim().toLocaleLowerCase(),
        ) !== undefined
      ) {
        filter.targetAudience = filter.targetAudience.filter(
          (e) => e !== element.key,
        );
      }
    }
    if (filterType === 'type') {
      if (
        element.key !== t('search.filter.all') &&
        filter.type &&
        filter.type.find(
          (e) =>
            e.trim().toLowerCase() === element.value.trim().toLocaleLowerCase(),
        ) === undefined
      ) {
        filter.type.push(element.key);
      } else if (element.key === t('search.filter.all')) {
        filter.type = [];
      } else if (
        element.key !== t('search.filter.all') &&
        filter.type &&
        filter.type.find(
          (e) =>
            e.trim().toLowerCase() === element.value.trim().toLocaleLowerCase(),
        ) !== undefined
      ) {
        filter.type = filter.type.filter((e) => e !== element.key);
      }
    }
    if (filterType === 'theme') {
      if (
        element.key !== t('search.filter.all') &&
        filter.breadcrumb &&
        filter.breadcrumb.find(
          (e) =>
            e.trim().toLowerCase() === element.value.trim().toLocaleLowerCase(),
        ) === undefined
      ) {
        filter.breadcrumb.push(element.key);
      } else if (element.key === t('search.filter.all')) {
        filter.breadcrumb = [];
      } else if (
        element.key !== t('search.filter.all') &&
        filter.breadcrumb &&
        filter.breadcrumb.find(
          (e) =>
            e.trim().toLowerCase() === element.value.trim().toLocaleLowerCase(),
        ) !== undefined
      ) {
        filter.breadcrumb = filter.breadcrumb.filter((e) => e !== element.key);
      }
    }

    setSearchFilter(filter);

    if (isRemoveTrigger === undefined) {
      onExpand(filterType);
    }
  };

  const addElemToArray = (array: FilterItemKeyValue[]) => {
    let res: FilterItemKeyValue[] = [];

    if (array) {
      if (array.filter((e) => e.key === 'All').length == 0)
        array.unshift({ key: 'All', value: t('search.filter.all') });

      res = array
        .filter((e) => e.value !== '')
        .map((e) => {
          return {
            key: e.value,
            value: e.value,
          };
        });
    }
    return res;
  };

  const onExpand = (filterType: FilterType) => {
    const filterItems = [...foldStatusItems];
    const updatedItems = filterItems.map((e) => {
      return {
        type: e.type,
        status: e.type === filterType ? !e.status : false,
      };
    });
    setFoldStatusItems(updatedItems);
  };

  const onRemoveFilter = (filterType: FilterType) => {
    const filterItems = [...foldStatusItems];
    const updatedItems = filterItems.map((e) => {
      return {
        type: e.type,
        status: false,
      };
    });

    updateSearchFilter(
      filterType,
      { key: t('search.filter.all'), value: t('search.filter.all') },
      true,
    );

    setFoldStatusItems(updatedItems);
  };

  const onForceCollapse = () => {
    const filterItems = [...foldStatusItems];
    const updatedItems = filterItems.map((e) => {
      return {
        type: e.type,
        status: false,
      };
    });
    setFoldStatusItems(updatedItems);
  };

  return (
    <div className="pb-[90px]">
      <div className="w-full absolute z-10">
        <div className="flex">
          <div className="flex-1 grow mr-2">
            <FilterItem
              key="target"
              icon={<img src={targetIcon} width={15} height={15} />}
              title={t('search.filter.targetAudience')}
              filterType="target"
              filterFacet={addElemToArray(props.targetAudienceFacets)}
              selectedItems={searchFilter.targetAudience}
              onClick={(t, i) => updateSearchFilter(t, i)}
              isFold={foldStatusItems[0].status}
              onExpand={() => onExpand('target')}
              onRemoveFilter={() => onRemoveFilter('target')}
              onForceCollapse={onForceCollapse}
            />
          </div>
          <div className="flex-1 grow mr-2">
            <FilterItem
              key="type"
              icon={<img src={fileIcon} width={12} height={14} />}
              title={t('search.filter.type')}
              filterType="type"
              filterFacet={addElemToArray(props.typeFacets)}
              selectedItems={searchFilter.type}
              onClick={(t, i) => updateSearchFilter(t, i)}
              isFold={foldStatusItems[1].status}
              onExpand={() => onExpand('type')}
              onRemoveFilter={() => onRemoveFilter('type')}
              onForceCollapse={onForceCollapse}
            />
          </div>
          <div className="flex-1 grow ">
            <FilterItem
              key={'theme'}
              icon={<img src={packageIcon} width={15} height={15} />}
              title={t('search.filter.theme')}
              filterType="theme"
              filterFacet={addElemToArray(props.breadcrumbFacets)}
              selectedItems={searchFilter.breadcrumb}
              onClick={(t, i) => updateSearchFilter(t, i)}
              isFold={foldStatusItems[2].status}
              onExpand={() => onExpand('theme')}
              onRemoveFilter={() => onRemoveFilter('theme')}
              onForceCollapse={onForceCollapse}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
