import { useEffect, useState } from 'react';
import guichetLogo from '/guichet-logo.png';
import { LanguageItem } from './LanguageItem';
import { Language } from '../../models/langEnum';
import { useSearchParams } from 'react-router-dom';
import { SubHeader } from './SubHeader';
import i18next from 'i18next';

export const Header = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [lang, setLang] = useState<Language>();

  const onClickLangBtn = (val: string) => {
    setLang(val as Language);
    i18next.changeLanguage(val);
    setSearchParams({ lang: val });
    window.location.reload();
  };

  useEffect(() => {
    setLang(searchParams.get('lang') as Language);
  }, [searchParams]);

  return (
    <>
      <div className="border-b-[5px] border-[#608100]">
        <div className="max-w-content mx-auto flex p-[8px] justify-between">
          <div className="absolute -left-[68px] -rotate-[39deg] bg-[#DA9D26] text-white text-[26px] text-[30px] px-[80px] py-2">
            <p>Demo</p>
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              window.location.reload();
            }}
          >
            <img src={guichetLogo} className="w-[115px] h-[18px]" />
          </div>
          <div className="flex">
            <LanguageItem
              value="FR"
              isActif={lang === Language.Fr}
              onClick={onClickLangBtn}
            />
            <LanguageItem
              value="DE"
              isActif={lang === Language.De}
              onClick={onClickLangBtn}
            />
            <LanguageItem
              value="EN"
              isActif={lang === Language.En}
              onClick={onClickLangBtn}
            />
          </div>
        </div>
      </div>
      <div className="max-w-content mx-auto">
        <SubHeader />
      </div>
    </>
  );
};
