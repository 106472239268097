import starIcon from '/starIcon.svg';

interface ProgressBarProps {
  value: number;
  label: string
}
export const ProgressBar = ({ value, label }: ProgressBarProps) => {
  value = Math.round(value * 100);
  if(value > 100) value = 99;

  return (
    <div className="inline-flex items-center space-x-1 text-white text-xs px-1">
      <img src={starIcon} alt="Star" />
      <span className='pr-1'>{label + `: ` + value}</span>
    </div>
  );
};
