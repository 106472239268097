import { useTranslation } from 'react-i18next';

interface SortItemProps {
  onChange: (value: string) => void;
  defaultValue?: string;
}

export const SortItem = (props: SortItemProps) => {
  const { onChange, defaultValue } = props;

  const { t } = useTranslation();

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="text-[#637255]">
      <span className="pr-1">{t('sort.title')}: </span>
      <select
        onChange={handleSortChange}
        className="rounded-[27px] w-[120px] border-2 border-[#ADB88D] indent-1 p-1.5 bg-white"
      >
        <option
          value="rerankerScore:desc"
          selected={defaultValue == 'rerankerScore:desc'}
        >
          {t('sort.reranker.descending')}
        </option>
        <option value="score:desc" selected={defaultValue == 'score:desc'}>
          {t('sort.score.descending')}
        </option>
      </select>
    </div>
  );
};

export default SortItem;
