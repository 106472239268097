import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import Layout from './pages/layout/Layout.tsx';
import { Error } from './pages/error/Error.tsx';
import axios from 'axios';
import { config, configUrl } from '../configuration/config.ts';
import { Search } from './components/search/Search.tsx';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { PublicClientApplication } from '@azure/msal-browser';
import { AuthSetup } from './models/AuthSetup.ts';
import { MsalProvider } from '@azure/msal-react';
import { MsalLogin } from './components/msalLogin/index.tsx';
import './i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

let layout;

if (config.useLogin) {
  const authSetup: AuthSetup = {
    tokenRequest: {
      scopes: [`api://${config.serverAppId}/user_impersonation`],
    },
    loginRequest: {
      scopes: ['https://graph.microsoft.com/.default'],
    },
    msalConfig: {
      auth: {
        authority: `https://login.microsoftonline.com/${config.tenantId}`,
        clientId: `${config.clientId}`,
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    },
    useLogin: true,
  };
  const msalInstance = new PublicClientApplication(authSetup.msalConfig);

  layout = (
    <MsalProvider instance={msalInstance}>
      <MsalLogin>
        <Layout />
      </MsalLogin>
    </MsalProvider>
  );
} else {
  layout = <Layout />;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: layout,
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: <Search />,
      },
    ],
  },
]);

const reactPlugin = new ReactPlugin();


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      gcTime: 0,
    },
  },
});

const App = (
  <React.StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => <h1>I believe something went wrong</h1>}
        appInsights={reactPlugin}
      >
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  </React.StrictMode>
);

if (import.meta.env.DEV) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: config.appInsightsConnectionString,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: {},
      },
    },
  });
  appInsights.loadAppInsights();
  ReactDOM.createRoot(document.getElementById('root')!).render(App);
} else {
  axios
    .get(configUrl)
    .then((res) => {
      Object.assign(config, res.data);
      return App;
    })
    .catch(() => (
      <p className="text-[red] text-center">
        Error while fetching global config
      </p>
    ))
    .then((reactElement: React.ReactElement) => {
      const appInsights = new ApplicationInsights({
        config: {
          connectionString: config.appInsightsConnectionString,
          extensions: [reactPlugin],
          enableAutoRouteTracking: true,
          extensionConfig: {
            [reactPlugin.identifier]: {},
          },
        },
      });
      appInsights.loadAppInsights();
      ReactDOM.createRoot(document.getElementById('root')!).render(
        reactElement,
      );
    });
}
