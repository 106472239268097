import { useEffect, useRef, useState } from 'react';
import ArrowDown from '/arrowdownIcon.svg';
import CloseRounded from '/closeRounded.svg';
import { FilterItemKeyValue, FilterType } from '../../models/Search';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

interface FilterItemProps {
  title: string;
  filterType: FilterType;
  icon: React.ReactNode;
  filterFacet: FilterItemKeyValue[];
  selectedItems: string[];
  onClick: (filterType: FilterType, item: FilterItemKeyValue) => void;
  onExpand: () => void;
  onRemoveFilter: () => void;
  isFold: boolean;
  onForceCollapse: () => void;
}

export const FilterItem = (props: FilterItemProps) => {
  const {
    title,
    icon,
    isFold,
    filterFacet,
    selectedItems,
    onClick,
    onRemoveFilter,
    onForceCollapse,
  } = props;
  const [selectedItem, setSelectedItem] = useState<string>();
  const [lang, setLang] = useState<string>('');

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const ref = useRef<any>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!selectedItem && filterFacet.length > 0) {
      setSelectedItem(filterFacet[0].value);
    }
  }, [filterFacet, selectedItem]);

  useEffect(() => {
    if (selectedItems.length === 0) {
      setSelectedItem(t('search.filter.all'));
    }
  }, [selectedItems]);

  useEffect(() => {
    if (searchParams && searchParams.get('lang') !== undefined) {
      setLang(searchParams.get('lang') ?? '');
    }
  }, []);

  useEffect(() => {
    if (lang !== searchParams.get('lang')) {
      setSelectedItem(undefined);
    }
  }, [searchParams, lang]);

  const handleOutsideClick = (e: Event) => {
    if (ref.current && !ref.current.contains(e.target)) {
      onForceCollapse();
    }
  };

  const checkItemInList = (item: FilterItemKeyValue): boolean =>
    selectedItems.find((e) => e === item.key) !== undefined;

  return (
    <div
      className="w-full border-2 border-[#ADB88D] rounded-[10px] p-2 shadow-[0_4px_9px_0_#D9D9D9] px-[20px] py-[15px] bg-[#fff] cursor-pointer"
      onClick={() => props.onExpand()}
    >
      <div className="flex items-center mb-1">
        <div className="mr-2">{icon}</div>
        <p className="uppercase text-sm font-semibold text-greenGuichet">
          {title}
        </p>
      </div>
      <div
        className={`flex justify-between ${
          isFold ? 'border-b-2 pb-[13px]' : ''
        }`}
      >
        <div className="flex align-center">
          <p className="mr-1">
            {selectedItems.length > 0
              ? `${selectedItems.length} ${
                  selectedItems.length > 1
                    ? t('search.filter.items')
                    : t('search.filter.item')
                }`
              : selectedItem}
          </p>
          {selectedItems.length > 0 ? (
            <img
              src={CloseRounded}
              height={18}
              width={18}
              className="z-30"
              onClick={(e) => {
                onRemoveFilter();
                setSelectedItem(t('search.filter.all'));
                e.stopPropagation();
              }}
            />
          ) : null}
        </div>
        <img src={ArrowDown} height={7} width={14} />
      </div>
      {isFold && filterFacet.length > 0 ? (
        <div className="max-h-[300px] overflow-x-hidden" ref={ref}>
          {filterFacet.map((e) => {
            return (
              <div
                key={e.key}
                className={`flex py-[10px] px-[20px] hover:bg-[#6372554D] mx-[-20px] cursor-pointer ${
                  checkItemInList(e) ? 'font-bold' : ''
                }`}
                onClick={() => {
                  setSelectedItem(e.value);
                  onClick(props.filterType, e);
                }}
              >
                <input
                  type="checkbox"
                  checked={checkItemInList(e)}
                  className="mr-1 cursor-pointer"
                />
                <p>{e.value}</p>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
