class Config {
  apiUrl: string = 'http://localhost:50505/';
  appInsightsConnectionString: string =
    'InstrumentationKey=0ec89eaf-3f75-44b9-a5c1-584569b5d1a0;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/';
  useLogin: boolean = true;
  serverAppId: string = '75a0f12c-cdf1-4e76-8334-7f3b6c2353a7';
  clientId: string = '75a0f12c-cdf1-4e76-8334-7f3b6c2353a7';
  tenantId: string = '8eb14bec-53be-4853-91f1-1da7befef467';
  userCtie: string = 'ctie@devoteam.lu';
}

export const config = new Config();

export const configUrl = 'config.json';
