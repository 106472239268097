import { SearchResultValue } from '../../models/Search.ts';
import fileIcon from '/fileIcon.svg';
import { ProgressBar } from './ProgressBar.tsx';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

interface SearchResultItemProps {
  searchId: string;
  rank: number;
  searchResult: SearchResultValue;
}
export const SearchResultItem = ({
  searchId,
  rank,
  searchResult,
}: SearchResultItemProps) => {
  const { t } = useTranslation();
  const appInsights = useAppInsightsContext();

  return (
    <div className="w-full bg-searchResult rounded-[11px] my-5 flex pl-[34px] py-3.5 pr-[23px] justify-between">
      <div className="flex">
        <div className="flex-none m-auto pr-[34px]">
          <a
            className="text-base"
            href={searchResult.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              appInsights.trackEvent({
                name: 'Click',
                properties: {
                  SearchServiceName: 'srch-semantifynd',
                  SearchId: searchId,
                  ClickedDocId: searchResult.url,
                  Rank: rank,
                  Themes: searchResult.breadcrumb,
                },
              });
            }}
          >
            <img src={fileIcon} alt="File" />
          </a>
        </div>
        <div className="grow">
          <div className="text-sm text-[#608100] mb-1">
            {format(parseISO(searchResult.date), 'dd/MM/yyyy')}
          </div>
          <a
            className="text-base"
            href={searchResult.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              appInsights.trackEvent({
                name: 'Click',
                properties: {
                  SearchServiceName: 'srch-semantifynd',
                  SearchId: searchId,
                  ClickedDocId: searchResult.url,
                  Rank: rank,
                  Themes: searchResult.breadcrumb,
                },
              });
            }}
          >
            <span className="font-bold">
              {searchResult.title.replace(/(<([^>]+)>)/gi, '')}
            </span>
          </a>
          {searchResult.highlights && (
            <div className="flex text-sm mt-4 mr-3">
              <div>
                <span>
                  {searchResult.highlights.replace(/(<([^>]+)>)/gi, '')}
                </span>
              </div>
            </div>
          )}
          <div className="flex text-sm mt-4">
            {searchResult.targetAudience?.split('/')[
              searchResult.targetAudience?.split('/').length - 1
            ].length > 0 ? (
              <div className="mr-14">
                <span className="font-bold">
                  {t('search.result.targetAudience')}:
                </span>{' '}
                {
                  searchResult.targetAudience?.split('/')[
                    searchResult.targetAudience?.split('/').length - 1
                  ]
                }
              </div>
            ) : null}
            {searchResult.types.length > 0 ? (
              <div className="mr-14">
                <span className="font-bold">{t('search.result.type')}:</span>{' '}
                {searchResult.types.join(', ')}
              </div>
            ) : null}
            {searchResult.breadcrumb.length > 0 ? (
              <div>
                <span className="font-bold">{t('search.result.theme')}:</span>{' '}
                {searchResult.breadcrumb
                  .map((e) => e.split('/')[e.split('/').length - 1])
                  .join(', ')}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="my-auto flex flex-col items-end text-right">
        <div className="w-[95px] bg-[#608100] rounded-[22px] mb-1">
          <ProgressBar
            label={t('reranker')}
            value={searchResult.rerankerScore}
          />
        </div>
        <div className="w-[75] bg-[#DA9D26] rounded-[22px]">
          <ProgressBar label={t('score')} value={searchResult.score} />
        </div>
      </div>
    </div>
  );
};
