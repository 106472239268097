import React from 'react';
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
interface MsalLoginProps {
  children: React.ReactNode;
}

export const MsalLogin = (props: MsalLoginProps) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated && inProgress === 'none') {
    instance
      .loginRedirect({
        scopes: ['user.read', 'openid', 'profile', 'offline_access'],
      })
      .catch((e) => {
        console.log(e);
      });
  }

  React.useEffect(() => {
    if (isAuthenticated) {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
      }
    }
  }, [isAuthenticated]);

  return <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>;
};
