// Refactored from https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/blob/main/1-Authentication/1-sign-in/SPA/src/authConfig.js

import {
  AuthenticationResult,
  CacheLookupPolicy,
  IPublicClientApplication,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';

// Build an absolute redirect URI using the current window's location and the relative redirect URI from auth setup
export const getRedirectUri = (redirectUrl: string) => {
  return window.location.origin + redirectUrl;
};

// Get an access token for use with the API server.
// ID token received when logging in may not be used for this purpose because it has the incorrect audience
export const getToken = (
  client: IPublicClientApplication,
): Promise<AuthenticationResult | undefined> => {
  return client
    .acquireTokenSilent({
      scopes: ['user.read', 'openid', 'profile', 'offline_access'],
      redirectUri: getRedirectUri('/'),
      cacheLookupPolicy: CacheLookupPolicy.Default,
    })
    .catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        await client.acquireTokenRedirect({
          scopes: ['user.read', 'openid', 'profile', 'offline_access'],
          redirectUri: getRedirectUri('/'),
        });
      } else {
        console.log(error);
        return undefined;
      }
    });
};
