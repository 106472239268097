interface FollowUpQuestionItemProps {
  value: string;
  onSelectValue: (value: string) => void;
}
export const FollowUpQuestionItem = (props: FollowUpQuestionItemProps) => {
  return (
    <div
      className="rounded-[5px] bg-[#F1F1F1] border-2 border-[#E1E1E1] mr-3 p-1 mb-2 cursor-pointer text-[14px]"
      onClick={() => props.onSelectValue(props.value)}
    >
      <p>{props.value}</p>
    </div>
  );
};
