import { FileUpload, FileUploadBeforeUploadEvent } from 'primereact/fileupload';
import { config } from '../../../configuration/config.ts';
import { useMsal } from '@azure/msal-react';
import { getToken } from '../../authConfig.ts';
import React from 'react';
import {useTranslation} from "react-i18next";

interface FileUploadProps {
  language: string;
  onAfterUpload: () => void;
}

export const FileUploadComponent = (props: FileUploadProps) => {
  const { language, onAfterUpload } = props;

  const { t } = useTranslation();

  const { instance } = useMsal();
  const client = config.useLogin ? instance : undefined;

  const [idToken, setIdToken] = React.useState<string>('');

  React.useEffect(() => {
    if (client) {
      getToken(client).then((e) => {
        setIdToken(e?.idToken ?? '');
      });
    }
  }, []);

  const chooseOptions = {
    label: t('parameters.addDocuments'),
    icon: <div></div>,
  };

  const beforeSend = (ev: FileUploadBeforeUploadEvent) => {
    ev.xhr.open('POST', url);
    ev.xhr.setRequestHeader('Authorization', 'Bearer ' + idToken);
  };

  const url = config.apiUrl + 'files/upload/' + language;
  return (
    <div className="mt-3">
      <FileUpload
        mode="basic"
        name="file"
        url={url}
        accept="*"
        maxFileSize={10000000}
        auto
        multiple
        chooseOptions={chooseOptions}
        className="rounded-[10px] bg-[#EDF5ED] text-[#637255] p-[10px] border-2 border-[#637255]"
        onBeforeSend={(e) => beforeSend(e)}
        onUpload={onAfterUpload}
      />
    </div>
  );
};
