import { SearchInput } from './SearchInput.tsx';
import { useEffect, useRef, useState } from 'react';
import { SearchResultsList } from './SearchResultsList.tsx';
import {
  FollowupQuestionsRequestItem,
  LanguageResult,
  SearchFilters,
  SearchResult,
  SemanticAnswerRequestItem,
  SuggestionResult,
} from '../../models/Search.ts';
import {
  getDocument,
  getFollowUpQuestionsQuery,
  getLanguage,
  getSearchResults,
  getSemanticAnswerQuery,
  getSuggestions,
} from '../../services/searchService.ts';
import { useSearchParams } from 'react-router-dom';
import { SearchSettingsModal } from './SearchSettingsModal.tsx';
import { SettingsRequest } from '../../models/SettingsRequest.ts';
import { Language } from '../../models/langEnum.ts';
import { Retrieve } from '../../models/retrieveEnum.ts';
import { useMsal } from '@azure/msal-react';
import { getToken } from '../../authConfig.ts';
import { config } from '../../../configuration/config.ts';
import { FollowUpQuestionsList } from './FollowUpQuestionsList.tsx';
import { LanguageChangeModal } from './LanguageChangeModal.tsx';
import { SemanticSearchResult } from './SemanticSearchResult.tsx';
import i18next from 'i18next';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

export const SearchPanel = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchId, setSearchId] = useState<string>('');
  const [searchResult, setSearchResult] = useState<SearchResult>();
  const [filtredSearchResult, setFiltredSearchResult] =
    useState<SearchResult>();
  const [followUpQuestions, setFollowUpQuestions] = useState<string[]>([]);
  const [semanticAnswer, setSemanticAnswer] = useState<string>();
  const [isResultsLoading, setIsResultsLoading] = useState<boolean>(false);
  const [lang, setLang] = useState<Language>(Language.Fr);
  const [searchLanguage, setSearchLanguage] = useState<LanguageResult>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalLang, setShowModalLang] = useState<boolean>(false);
  const [isQueryFollowUpQuestion, setIsQueryFollowUpQuestion] =
    useState<boolean>(false);

  const initValue = {
    MinScore: 0,
    MinReranker: 0,
    ResultPerPage: 5,
    MaxResult: 25,
    EnableSemanticResult: false,
    EnableFollowUpQuestion: true,
    Sorting: 'rerankerScore:desc',
  };
  const [searchSettings, setSearchSettings] =
    useState<SettingsRequest>(initValue);

  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [isModalInformation, setIsModalInformation] = useState(false);
  const [localStorage, setLocalStorage] =
    useLocalStorage<SettingsRequest>('searchSettings');

  const controller = useRef(new AbortController());
  const signal = useRef(controller.current.signal);

  const controllerFQ = useRef(new AbortController());
  const signalFq = useRef(controllerFQ.current.signal);

  const controllerSR = useRef(new AbortController());
  const signalSR = useRef(controllerSR.current.signal);

  const { instance } = useMsal();
  const client = config.useLogin ? instance : undefined;

  useQuery({
    queryKey: ['getFUQ', searchLanguage, searchResult],
    queryFn: async () => {
      getFollowUpQuestions({
        values: searchResult!.values,
        language: searchLanguage!.language,
      });
      return {};
    },
    enabled:
      searchLanguage && searchResult && searchSettings.EnableFollowUpQuestion,
  });

  useQuery({
    queryKey: ['getSemanticResult', searchLanguage, searchResult],

    queryFn: async () => {
      getSemanticAnswer({
        values: searchResult!.values,
        language: searchLanguage!.language,
      });
      return {};
    },
    enabled:
      searchLanguage && searchResult && searchSettings.EnableSemanticResult,
  });

  useEffect(() => {
    if (localStorage === undefined) {
      setLocalStorage(initValue);
    } else {
      setSearchSettings(localStorage);
    }
  }, []);

  useEffect(() => {
    if (searchSettings && searchSettings !== initValue) {
      setLocalStorage(searchSettings);
    }
  }, [searchSettings]);

  useEffect(() => {
    if (searchValue && isQueryFollowUpQuestion) {
      search();
      setIsQueryFollowUpQuestion(false);
    }
  }, [searchValue, isQueryFollowUpQuestion]);

  useEffect(() => {
    if (searchParams && searchParams.get('lang') !== null) {
      setLang(searchParams.get('lang') as Language);
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      searchLanguage &&
      searchLanguage.languageIsoCode !== '' &&
      searchLanguage.title !== '' &&
      searchLanguage.text !== '' &&
      searchLanguage.languageIsoCode != lang.toString()
    ) {
      setShowModalLang(true);

      if (
        !Object.values(Language).includes(
          searchLanguage.languageIsoCode as Language,
        )
      )
        setIsModalInformation(true);
    }
  }, [searchLanguage]);

  const getFollowUpQuestions = async (searchResult: SearchResult) => {
    if (!searchResult || !searchSettings.EnableFollowUpQuestion) return;

    const token = client ? await getToken(client) : undefined;

    setFollowUpQuestions([]);
    getFollowUpQuestionsQuery(
      {
        search: searchValue,
        sources:
          searchResult &&
          searchResult.values.slice(0, 5).map((e) => {
            return {
              score: e.rerankerScore,
              title: e.title,
            } as FollowupQuestionsRequestItem;
          }),
        numberOfQuestions: 5,
        language: searchLanguage!.language,
      },
      token?.idToken,
      signalFq.current,
      instance,
    )
      .then((res) => {
        searchResult.values[0].score !== 1
          ? setFollowUpQuestions(res.followupQuestions)
          : setFollowUpQuestions([]);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        resetAbortControllerFQ();
      });
  };

  const getSemanticAnswer = async (searchResult: SearchResult) => {
    if (!searchResult) return;

    const token = client ? await getToken(client) : undefined;

    setSemanticAnswer(undefined);
    getSemanticAnswerQuery(
      {
        search: searchValue,
        language: searchLanguage!.language,
        sources:
          searchResult &&
          searchResult.values.slice(0, 3).map((e) => {
            return {
              score: e.rerankerScore,
              title: e.title,
              path: e.url,
              contents: e.contents,
            } as SemanticAnswerRequestItem;
          }),
      },
      token?.idToken,
      instance,
      signalSR.current,
    )
      .then((res) => {
        setSemanticAnswer(res.semantic_answer);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        resetAbortControllerSR();
      });
  };

  const resetAbortController = () => {
    controller.current = new AbortController();
    signal.current = controller.current.signal;
  };

  const resetAbortControllerFQ = () => {
    controllerFQ.current = new AbortController();
    signalFq.current = controllerFQ.current.signal;
  };

  const resetAbortControllerSR = () => {
    controllerSR.current = new AbortController();
    signalSR.current = controllerSR.current.signal;
  };

  const search = async (langParam?: Language) => {
    controller.current.abort();
    controllerFQ.current.abort();
    controllerSR.current.abort();

    const token = client ? await getToken(client) : undefined;
    setSearchId(`${uuidv4()}`);

    setSearchLanguage(undefined);

    getLanguage(
      {
        search: searchValue,
        language: lang.toString() ?? Language.Fr.toString(),
      },
      token?.idToken,
      instance,
    ).then((result) => {
      setSearchLanguage({
        languageIsoCode: result.languageIsoCode,
        language: result.language,
        text: result.text,
        title: result.title,
      });
    });
    setSearchResult(undefined);
    setFiltredSearchResult(undefined);
    setFollowUpQuestions([]);
    setSemanticAnswer(undefined);
    setIsResultsLoading(true);
    getSearchResults(
      {
        search: searchValue,
        language: langParam
          ? langParam
          : lang.toString() ?? Language.Fr.toString(),
        top: searchSettings.MaxResult,
        minScore: searchSettings.MinScore ?? 0,
        minReranker: searchSettings.MinReranker ?? 0,
        orderBy: searchSettings.Sorting?.split(':')[0] ?? 'rerankerScore',
        orderByDirection: searchSettings.Sorting?.split(':')[1] ?? 'desc',
        retrieveMethod: Retrieve.Vector,
        enableSemantic: true,
        enableSemanticResult: searchSettings.EnableSemanticResult,
        searchId: searchId,
      },
      token?.idToken,
      instance,
    )
      .then((result) => {
        setSearchResult(result);
        setFiltredSearchResult(result);
      })
      .catch((error) => {
        console.log('Got error: ' + error);
      })
      .finally(() => {
        setIsResultsLoading(false);
        resetAbortController();
        resetAbortControllerFQ();
        resetAbortControllerSR();
      });
  };

  const fetchDocument = async (id: string) => {
    setSearchResult(undefined);
    setFiltredSearchResult(undefined);
    setSemanticAnswer(undefined);
    setIsResultsLoading(true);
    setFollowUpQuestions([]);
    const token = client ? await getToken(client) : undefined;
    getDocument(
      {
        id: id,
        search: searchValue,
        language: lang.toString(),
        retrieveMethod: Retrieve.Vector,
        enableSemantic: true,
        searchId: searchId,
        minScore: searchSettings.MinScore ?? 0,
        minReranker: searchSettings.MinReranker ?? 0,
        orderBy: searchSettings.Sorting?.split(':')[0] ?? 'rerankerScore',
        orderByDirection: searchSettings.Sorting?.split(':')[1] ?? 'desc',
      },
      token?.idToken,
      instance,
    )
      .then((result) => {
        setSearchResult(result);
        setFiltredSearchResult(result);
      })
      .catch((error) => {
        console.log('Got error: ' + error);
      })
      .finally(() => {
        setIsResultsLoading(false);
      });
  };

  const fetchAutocompleteValues = async (
    search: string,
    onSuggestionsFetched: (result: SuggestionResult) => void,
  ) => {
    if (isResultsLoading) return;

    setIsLoadingSuggestions(true);
    const token = client ? await getToken(client) : undefined;
    getSuggestions(
      {
        search: search,
        language: searchParams.get('lang') ?? lang,
        top: 5,
      },
      signal.current,
      token?.idToken,
      instance,
    )
      .then((result) => {
        onSuggestionsFetched(result);
      })
      .catch((error) => {
        console.log('Got error: ' + error);
      })
      .finally(() => {
        setIsLoadingSuggestions(false);
      });
  };

  const filterResult = (filter: SearchFilters) => {
    const filteredResult = Object.assign({}, searchResult);

    if (filter.breadcrumb && filter.breadcrumb.length > 0) {
      const valuesFiltered = filteredResult?.values.filter((e) =>
        filter.breadcrumb?.some(
          (p) => e.breadcrumb && e.breadcrumb.includes(p),
        ),
      );

      filteredResult.values = valuesFiltered;
    }
    if (filter.targetAudience && filter.targetAudience.length > 0) {
      const valuesFiltered = filteredResult?.values?.filter((e) =>
        filter.targetAudience?.includes(
          e.targetAudience && e.targetAudience.split('/')[1],
        ),
      );

      filteredResult.values = valuesFiltered;
    }
    if (filter.type && filter.type.length > 0) {
      const valuesFiltered = filteredResult?.values?.filter((e) =>
        filter.type?.some((p) => e.types && e.types.includes(p)),
      );
      filteredResult.values = valuesFiltered;
    }
    setFiltredSearchResult(filteredResult);
  };

  const sortResult = (sort: string) => {
    setSearchSettings(initialState => { return { ...initialState, Sorting: sort }});
    const filteredResult = Object.assign({}, searchResult);
    const sortField = sort.split(':')[0];
    const sortDirection = sort.split(':')[1] == 'asc' ? 'asc' : 'desc';
    filteredResult.values = _.orderBy(filteredResult.values, sortField, sortDirection);
    setFiltredSearchResult(filteredResult);
  };

  const onConfirmChangeLanguage = async () => {
    if (isModalInformation) {
      setIsModalInformation(false);
    } else {
      controller.current.abort();
      controllerFQ.current.abort();
      controllerSR.current.abort();
      setFollowUpQuestions([]);
      setSemanticAnswer(undefined);
      await i18next.changeLanguage(searchLanguage!.languageIsoCode);
      setLang(searchLanguage!.languageIsoCode as Language);
      setSearchParams({ lang: searchLanguage!.languageIsoCode });
      await search(searchLanguage!.languageIsoCode as Language);
    }
    setShowModalLang(false);
  };

  return (
    <>
      <div>
        <div
          className={`mb-10 bg-cover bg-center bg-[url(/banner-bg.jpg)] ${
            searchSettings && searchSettings.EnableFollowUpQuestion
              ? 'h-[450px]'
              : 'h-[250px]'
          }  flex flex-col p-10`}
        >
          <div className="max-w-content mx-auto w-full flex items-center pb-24">
            <div className="w-full pt-10">
              <SearchInput
                value={searchValue}
                onChange={(value) => setSearchValue(value)}
                onClick={() => search()}
                onHitEnter={() => search()}
                fetchAutocompleteValues={fetchAutocompleteValues}
                handleSelectAutocomplete={(value) => fetchDocument(value)}
                onClickParams={() => setShowModal(true)}
                isLoadingSuggestions={isLoadingSuggestions}
              />
            </div>
          </div>
          <FollowUpQuestionsList
            followUpQuestions={followUpQuestions}
            setSelectedValue={(v) => {
              setSearchValue(v);
              setIsQueryFollowUpQuestion(true);
            }}
          />
        </div>
        {semanticAnswer && <SemanticSearchResult text={semanticAnswer} />}
        <div>
          <SearchResultsList
            initResults={searchResult}
            results={filtredSearchResult}
            isLoading={isResultsLoading}
            maxPerPage={
              searchSettings.ResultPerPage < 5
                ? 5
                : searchSettings.ResultPerPage
            }
            filterResult={filterResult}
            searchId={searchId}
            sortResult={sortResult}
          />
        </div>
      </div>
      <SearchSettingsModal
        initValues={searchSettings}
        isShowModal={showModal}
        lang={lang}
        onClose={() => setShowModal(false)}
        onValidate={(s) => {
          setSearchSettings(s);
          setShowModal(false);
        }}
      />
      {searchLanguage ? (
        <LanguageChangeModal
          isShowModal={showModalLang}
          onReject={() => {
            setShowModalLang(false);
            setIsModalInformation(false);
          }}
          onClose={() => {
            setShowModalLang(false);
            setIsModalInformation(false);
          }}
          text={searchLanguage.text}
          isInformation={isModalInformation}
          title={searchLanguage.title}
          onConfirm={() => onConfirmChangeLanguage()}
          searchLanguage={searchLanguage.languageIsoCode}
        />
      ) : null}
    </>
  );
};
